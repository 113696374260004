@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/base/helpers.sass";
@import "bulma/sass/base/minireset.sass";
@import "bulma/sass/grid/_all.sass";
@import "bulma/sass/layout/_all.sass";
@import "bulma/sass/elements/container.sass";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~@blueprintjs/table/lib/css/table.css";
@import "~@blueprintjs/core/src/common/variables";
@import "~xterm/css/xterm.css";

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
}

body.bp3-dark {
  background-color: #30404d;
}

.stat-card {
  padding:20px;
  font-size: .75em;
  text-align: right;
  h1 {
    font-size: 4em !important;
    line-height: 1em !important;
  }
}

.inline-flex {
  display: inline-flex;
}

.bp3-form-group.bp3-inline.inline-flex {
  .bp3-checkbox {
    margin-bottom: 5px;
  }
}

.column.bp3-inline > .bp3-form-group {
  vertical-align: middle;
}

.min-width-tag-input {
  min-width: 200px;
}

.bp3-multi-select-popover .bp3-menu {
    max-width: 400px;
    max-height: 300px;
    overflow: auto;
}

.popover-sz {
  .bp3-menu {
    max-height: 400px;
    overflow-y: auto;
  }
}

.map-hero {
  height: calc(100vh - 100px - 126px - 69px - 16px);
  min-height: 400px;
  padding: 0;
  .hero-body {
    position: relative;
    > .container {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
    }
  }
}

.dash-status-pop {
  min-width: 350px;
}

.dash-status-tree {
  height: 200px;
  overflow-y: scroll
}

.bp3-tree-node-secondary-label {
  line-height:1.28581;
}

.non-ideal-device-card {
  padding: 123px 0;
}

table {
  width: 100%;
}

$sizeUnit: rem;
$marginKey: 'm';
$paddingKey: 'p';
$separator: '-';
$sizes: (
    ('none', 0),
    ('xxs', 0.125),
    ('xs', 0.25),
    ('sm', 0.5),
    ('md', 1),
    ('lg', 2),
    ('xl', 4),
    ('xxl', 8),
);
$positions: (
    ('t', 'top'),
    ('r', 'right'),
    ('b', 'bottom'),
    ('l', 'left')
);

@function sizeValue($key, $value) {
    @return if($key == 'none', 0, $value + $sizeUnit);
}

@each $size in $sizes {
    $sizeKey: nth($size, 1);
    $sizeValue: nth($size, 2);
    .#{$marginKey}#{$separator}#{$sizeKey} {
        margin: sizeValue($sizeKey, $sizeValue);
    }
    .#{$paddingKey}#{$separator}#{$sizeKey} {
        padding: sizeValue($sizeKey, $sizeValue);
    }
    @each $position in $positions {
        $posKey: nth($position, 1);
        $posValue: nth($position, 2);
        .#{$marginKey}#{$separator}#{$posKey}#{$separator}#{$sizeKey} {
            margin-#{$posValue}: sizeValue($sizeKey, $sizeValue);
        }
        .#{$paddingKey}#{$separator}#{$posKey}#{$separator}#{$sizeKey} {
            padding-#{$posValue}: sizeValue($sizeKey, $sizeValue);
        }
    }
}

/* Variables *\/
$vin-blue: #5bb7db;
$vin-green: #60b044;
$vin-red: #ff0000;

/* Styles *\/
body {
  font: 14px 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.4em;
  color: #4d4d4d;
  min-width: 230px;
  max-width: 550px;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 300;
}

td {
	padding: 12px;
}

h2 {
	color: $vin-blue;
}

.savings { color: $vin-green; }
.loss { color: $vin-red; }
input.small { width: 46px; }
td.fuel-savings-label { width: 175px; }
*/

/*!
 * https://github.com/YouCanBookMe/react-datetime
 */


.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

.logo-selector {
  display:inline-block;
  > .bp3-non-ideal-state {

  }
  .bp3-file-input {
    text-align: left;
  }
  .with-logo {
    text-align: center;
    > img {
      display: inline-block;
      padding: 15px 0;
      max-height: 200px;
    }
    > div {text-align: center;}
  }
}

.operating-day {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
}

.ground-truth {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0 8px 0px 8px;
}

.passerby-select {
  color: initial;
}

.custom-container {
  margin: 5px 25px 5px 25px;
}

.pipeline-info {
  display: flex;
  align-items: center;
}

.bp3-alert-contents {
  width: 100%;
}

.service-form {
  .bp3-select {
    width: 100%;
  }
}

.text-right {
  text-align: right;
}

$icon-classes: (
  ".bp3-icon",
  ".bp3-icon-standard",
  ".bp3-icon-large"
) !default;

.bp3-tree {
  #{$icon-classes} {
    color: $pt-icon-color;

    @each $intent, $colors in $pt-intent-colors {
      &.bp3-intent-#{$intent} {
        @include intent-color($intent);
      }
    }
  }
}

.camera-vision-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

#render-camera-img {
  padding: 0px;
}

.flex-with-margin {
  flex-grow: 1;
  margin-right: 10px;
}

.flex-with-no-margin {
  flex-grow: 1;
}

.flex-space-between-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-start-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.camera-vision-image {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.camera-instructions {
  margin-left: 30px;
  flex-grow: 1;
  > div {
    margin-bottom: 10px;
    > p {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
  > ol > li {
    margin-bottom: 10px;
  }
  > ol > li > div {
    margin-top: 5px;
    button {
      margin-right: 5px;
    }
  }
}

.vision-form-container {
  margin-top: 40px;
  width: 70%;
}

.edge-container {
  margin-top: 10px;
  button {
    margin-right: 5px;
  }
}

.blue-selected-edge {
  background-color: #0E78E2 !important;
}

.red-selected-edge {
  background-color: #DE4849 !important;
}

.direction-configured-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .previously-configured {
    color: #fff;
    font-size: 16px;
    margin-right: 20px;
  }
}

.generate-image-message {
  width: 40%;
  margin-top: 10px;
  > span {
    color: #48aff0;
    margin: 0px 5px;
  }
}

.direction-configuration {
  display: flex;
  margin-left: 20px;
  flex-direction: column;
  justify-content: start;
  > button {
    height: 30px;
  }
}

.column-eq {
  display: flex;
  & > :first-child {
    flex-grow: 1;
  }
}

.bp3-navbar-group > .bp3-tabs > .bp3-tab-list.bp3-large > .bp3-tab {
  line-height: 50px;
}

.whitelist-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > li {
    display: flex;
    .mac-entry {
      padding: 5px 0px;
    }
    .mac-delete {
      padding: 5px 25px 0px 0px;
    }
  }
}

.camera-config-margin {
  margin-right: 10px;
  margin-bottom: 10px;
}

.audit-summary {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .audit-summary-row {
    max-width: 100%;
    .audit-summary-row-item {
      > div {
        margin-bottom: -15px;
      }
    }
  }
}

.audit-header {
  line-height: 1.28581;
  display: inline;
  margin: 10px 0px 0px 0px;
  font-weight: bold;
}

.audit-back-icon {
  cursor: pointer;
  margin: 0px 15px 5px 0px;
  color: white;
  &:hover {
    color: #2686C1;
  }
}

.audit-filter-container {
  margin-top: 20px;
  display: flex;
  .filter-form-group {
    font-size: 14px;
  }
  .filter-form-group:not(:first-child) {
    margin-left: 10px;
  }
  .submit-group {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
}

.pause-audit {
  &:hover {
    color: #0E78E2;
  }
}

.audit-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .pause-audit-container {
    display: inline-block;
    > .bp3-icon {
      cursor: pointer;
    }
    > .bp3-icon:first-child {
      margin-right: 15px;
    }
  }
}

.audit-context-menu {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 200px;
  min-width: 250px;
  max-width: 400px;
  flex-wrap: wrap;
  .clip-name {
    font-size: 16px;
  }
}

.reid-audit-context-menu {
  padding: 20px;
  display: flex;
  justify-content: space-around;
  min-height: 200px;
  min-width: 250px;
  max-width: 1200px;
  max-height: 800px;
  flex-wrap: wrap;
}

.compare-info {
  display: flex;
  align-items: center;
  font-weight: 450;
  > .bp3-tag {
    font-size: 14px;
  }
}

.center-text {
  text-align: center;
  &.summary {
    margin-top: 10%;
  }
}

.bp3-heading.bp3-intent-danger  {
  color: #ff7373;
}

#render-camera-img-polygon2 {
  position: relative;
  width: 50%;
}

.align-center-container {
  display: flex;
  align-items: center;
}

.custom-text-area {
  background-color: #27333E;
  width: 100%;
  margin-top: 3px;
  border: none;
  padding: 5px;
  border-radius: 4px;
}

.card-dash-overlay {
  background-color: #30404D;
  min-height: 300px;
  min-width: 700px;
  max-height: 600px;
  overflow-y: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-property {
  text-align: center;
  .title {
    font-size: 1.5rem;
  }
  .value {
    font-size: 2rem;
    margin-top: 16px;
    font-weight: 500;
  }
}

.card-header-title {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 16px;
  > .bp3-tag {
    font-size: 16px;
  }
}

.dash-overlay {
  background-color: #30404D;
  min-height: 200px;
  min-width: 700px;
  max-height: 600px;
  overflow-y: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dash-date-card {
  margin: 20px 0px;
  display: flex;
  > h3 {
    margin-right: 20px;
  }
}

.row-align-center {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
}

.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}
